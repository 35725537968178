import React from "react";
import { HydraAdmin, ResourceGuesser } from "@api-platform/admin";
import { DomainCreate, DomainEdit, DomainList } from "./Crud/Domains/Domain";

import customTheme from "./customTheme";
import customLayout from "./customLayout";

let entrypoint = process.env.REACT_APP_API_URL;

// Replace with your own API entrypoint
// For instance if https://example.com/api/books is the path to the collection of book resources, then the entrypoint is https://example.com/api

const App = () => (
  <HydraAdmin entrypoint={entrypoint} theme={customTheme} layout={customLayout} disableTelemetry>
    <ResourceGuesser
      name="domains"
      edit={DomainEdit}
      create={DomainCreate}
      list={DomainList}
    />
    <ResourceGuesser name="services" />
  </HydraAdmin>
);

export default App;
